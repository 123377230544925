.app-div {
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
}

table {
  border: solid 1px black;
  border-collapse: collapse;
}

td, th {
  border: solid 1px black;
  padding: 5px;
}

.fake-link {
  text-decoration: underline;
  cursor: pointer;
}

.table-list {
  padding-bottom: 20px;
}

.submission-container {
  flex: 1;
  display: flex;
  overflow-y: auto;
  width: 100%;
}

.submission-list {
  background-color: aliceblue;
  padding: 5px;
  overflow-y: auto;
  border: solid 1px black;
}

.student-entry {
  margin: 2px;
  padding: 10px;
}

.submitted {
  background-color: lightgreen;
}

.unsubmitted {
  background-color: pink;
}

.selected-student {
  border: solid 3px blue;
}

.submission-code {
  padding: 15px;
  width: 800px;
  font-family: "Fira code", "Fira Mono", Courier, monospace;
  font-size: 0.8em;
  overflow-y: auto;
  border: solid 1px black;
  flex: 2;
}

.submission-tests {
  background-color: #F8F8F8;
  padding: 15px;
  overflow-y: auto;
  border: solid 1px black;
  flex: 1;
}

.student-message {
  padding: 4px;
  background-color: #FFE;
}


.test-results-table {
  width: 100%;
  border-collapse: collapse;
  border: solid 1px black;
}

.test-result {
  border-bottom: solid 1px black;
}

.test-result-description {
  font-size: 0.8em;
}

.test-result-pass-fail {
  font-weight: bold;
  font-size: 0.8em;
  padding-right: 5px;
}

.test-result-pass {
  color: green;
}

.test-result-fail {
  color: red;
}

.test-result-details-table {
  width: 100%;
  border: solid 1px black;
  border-collapse: collapse;
}

.test-result-details-table > thead > tr > th, 
.test-result-details-table > tbody > tr > td
{
  border: solid 1px black;
  padding-left: 3px;
  padding-right: 3px;
}

.test-result-early-termination-message {
  background-color: yellow;
  padding: 2px;
}

.student-message-in-summary {
  background-color: lightgray;
  padding: 10px;
}